<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        inline
      >
        <el-form-item prop="explicit">
          <span>外显包: </span>
          <el-select
            v-model="formData.app_key"
            placeholder="选择APP"
            collapse-tags
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.app_key"
            />
            <el-option
              label="其他"
              value="other"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="state">
          <span>状态: </span>
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="item in categoryStateListOne"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #right>
        <el-button
          type="prrmary"
          @click="EditItem()"
        >
          添加
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="formData.loading"
      :data="formData.bottomList"
      style="width: 100%"
    >
      <el-table-column
        label="排序"
        prop="rank"
      />
      <el-table-column
        label="菜单名称"
        prop="name"
      />
      <el-table-column
        label="图标"
        min-width="120"
      >
        <template v-slot="{row}">
          <div class="img">
            <el-image
              :src="row.icon"
              lazy
            />
            <!-- <el-image
              :src="row.focus_icon"
              lazy
            /> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="外显包"
        prop="names"
      />
      <el-table-column
        label="状态"
        prop="categoryName"
        width="110px"
      />
      <el-table-column
        label="操作"
      >
        <template #default="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="EditItem(row)"
          />
          <BTextButton
            :title="row.status === 1 ? '禁用' : '启用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleRowState(row)"
          />
          <!-- <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          /> -->
        </template>
      </el-table-column>
    </el-table>
  </Layout>
</template>

<script>
import {
  getMenus,
  updateMenu
} from '@/api/menu-disposition.js'
import { commonRequestGet } from '@/api/commonApi.js'
import { categoryStateListOne } from '@/utils/selectOptions'
export default {
  components: {

  },
  data () {
    return {
      categoryStateListOne,
      formData: {
        app_key: '',
        explicit: '', // 外显包
        status: 1, // 状态
        loading: false,
        bottomList: []
      },
      // 外显包
      appKeysList: [],
      categoryStateList1: [] // 声明一个空数组
    }
  },
  created () {
    this.getAppKeys()
    this.getMenus()
    this.handleSearch()
    this.formData.app_key = localStorage.getItem('discover_app_key')
    const storedStatus = localStorage.getItem('discover_status')
    this.formData.status = storedStatus !== null ? parseInt(storedStatus) : 1
    this.categoryStateList1 = categoryStateListOne // 赋值为全局变量categoryStateList
    console.log(this.categoryStateList1)
  },
  methods: {
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    // 表格数据
    getMenus () {
      this.getAppKeys().then(() => {
        this.formData.loading = true
        getMenus({ position: 'discover', app_key: this.formData.app_key, status: this.formData.status }).then(res => {
          this.formData.bottomList = res.data.map(item => {
            const appKeys = item.app_keys || []
            const categoryState = item.status
            const names = appKeys.map(key => this.appKeysNameMap[key]).join('、')
            // 找到匹配的name
            const statusname = this.categoryStateList1.find(key => key.value === categoryState)
            const categoryName = statusname ? statusname.name : ''
            return {
              ...item,
              names: names,
              categoryName: categoryName
            }
          })
        })
          .finally(() => {
            this.formData.loading = false
          })
      })
    },
    // 搜索
    handleSearch () {
      this.getMenus()
    },
    // 添加和编辑
    EditItem (row) {
      localStorage.setItem('discover_app_key', this.formData.app_key || '')
      localStorage.setItem('discover_status', this.formData.status)
      if (row) {
        this.$router.push({
          name: 'discoverEdit',
          query: { row: row }
        })
      } else {
        this.$router.push({ name: 'discoverEdit' })
      }
    },
    handleToggleRowState (row) {
      const param = {
        status: row.status
      }
      const id = row.id
      const targetStatus = param.status === 1 ? 0 : 1
      const tips = targetStatus === 1 ? '此操作将启用，是否继续?' : '此操作将禁用，是否继续?'

      this.$confirm(tips, '提示', { type: 'warning' })
        .then(async () => {
          try {
            this.$set(row, 'status', targetStatus)
            await updateMenu(id, { status: targetStatus })
            this.getMenus()
          } catch (err) {
            this.$message.error(err.response.data.message)
            this.getMenus()
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.img {
  display: flex;
  .el-image{
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
</style>
